import common_en from './common_en.json'
import common_ru from './common_ru.json'

/**
 * Класс для хранения переводов и их динамической загрузки
 */
class I18n {
  en = common_en
  ru = common_ru

  private subscribers = []

  getMessages() {
    return { en: this.en, ru: this.ru }
  }

  append(translations: Translations[]) {
    for (const { prefix, en, ru } of translations) {
      copy(this.en, en, prefix)
      copy(this.ru, ru, prefix)
    }

    this.subscribers.forEach((cb) => cb(this.getMessages()))
  }

  subscribe(cb) {
    this.subscribers.push(cb)

    return () => {
      this.subscribers = this.subscribers.filter((s) => s !== cb)
    }
  }
}

function copy(target: Record<string, string>, source: Record<string, string>, prefix: string) {
  for (const [key, value] of Object.entries(source)) {
    target[prefix + key] = value
  }
}

interface Translations {
  prefix: string
  en: Record<string, string>
  ru: Record<string, string>
}

const i18n = new I18n()
export default i18n
